@import url("https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap");

* {
  /* font-family: 'Titillium Web', sans-serif; */
  font-family: "Helvetica", sans-serif;
}

.tabela-cautela {
  border-collapse: separate !important;
}

@media print {
  .tabela-cautela th,
  .tabela-cautela td {
    border: 2px solid black !important;
  }
}

.tabela-cautela th,
.tabela-cautela td {
  border: 1px solid black;
}

html {
  height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0 0 20 0;
  height: 100%;
}

#root {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

table.sticky {
  position: relative;
  border-collapse: collapse;
  overflow-x: auto;
}

table.sticky th.sticky {
  position: sticky;
  background-color: white;
  top: 0px;
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}

.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}

@media print {
  .leaflet-control-zoom {
    display: none;
  }
}