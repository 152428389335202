#eleicoes {
  font-family: sans-serif !important;
  box-shadow: 0 0 50px 0;
}

#eleicoes.container {
  box-shadow: 0px 0px 40px 0px;
}


.cor-eleicao{
background: #0d204b;
}

#eleicoes .bg-light {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(200, 200, 200) 100%
  );
}

#eleicoes .bg-danger {
  background: rgb(238, 91, 104);
  background: linear-gradient(
    180deg,
    rgba(238, 91, 104, 1) 0%,
    #ec2939 100%
  );
}
#eleicoes .bg-primary {
  background: #0071ce !important;
  background: linear-gradient(
    180deg,
    rgba(0, 129, 235, 1) 0%,
    rgba(0, 82, 149, 1) 100%
  ) !important;
}

#eleicoes .bg-dark-translucent {
  background: rgba(0,0,0,.06) !important;
}

#eleicoes .borda-redonda {
  border-radius: 2rem;
}

#eleicoes .borda-meio-redonda {
  border-radius: 2rem 2rem 0 0;
}

#eleicoes .borda-branca {
  border: solid white 1px; 
}