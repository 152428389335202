.rounded-image {
    border-radius: 5px;
    width: 100%;

}

/* styles.css */
.justify-text {
    text-align: justify;
}

.background {
    background-color: #ADD8E6;
}


.white-background {
    background-color: white;
}


.shake {
    animation: shake 0.5s;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    20% {
        transform: translateX(-10px);
    }

    40% {
        transform: translateX(10px);
    }

    60% {
        transform: translateX(-10px);
    }

    80% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}

.container_principal {
    margin: 0;
    padding: 0;
}

.backgroundTitle {
    background: rgb(13, 202, 240);
    background: linear-gradient(180deg, rgba(13, 202, 240, 1) 0%, rgba(169, 197, 224, 1) 100%);
}

.backgroundTitle h1 {
    position: relative;
    /* Para garantir que a sombra de texto fique atrás do texto */
    z-index: 1;
    /* Garante que o texto fique na frente da sombra */
    background-color: rgba(255, 255, 255, 0.2);
    /* Cor de fundo branco translúcido */
    padding: 10px;
    /* Espaçamento interno */
    border-radius: 10px;
    /* Borda arredondada */
}


.backgroundBody {
    background-color: #a9c5e0;
}


@media print {

    .chart-container,
    #map {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        page-break-inside: avoid;
    }

}

.hr-full-width {
    width: 100%;
    margin: 0;
    color: black
}

.borda-arredondada-topo {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
}

.chart-container {
    position: relative;
    height: 640px;
    padding: 15px;
    margin-top: 10px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chart-wrapper {
    height: 90%;
    width: 100%;
}

.legend {
    position: absolute;
    top: 50%;
    right: 30px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transform: translateY(-50%);
}

.legend h4,
.legend p {
    margin: 0;
}

.titulo-suave {
    color: #333;
    /* Tom suave */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    /* Sombra de texto */
    text-align: center;
    /* Centralizar texto */
}

.chart-container-map {
    position: relative;
    height: 690px;
    padding-top: 10px;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 10px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.full-width-select {
    width: 100%;
}
