.linha_cards{
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 15px;
}

.card-prazo {
  transition: transform 0.3s ease;
  cursor: pointer;
  color: #fff; /* Deixa o texto branco para contraste */
}

.card-prazo:hover {
  transform: scale(1.05); /* Efeito de hover para aumentar o card */
}

.no-prazo {
  background-color: #28a745 !important; /* Verde */
}

.prorrogada {
  background-color: #ffc107 !important;/* Amarelo */
}

.atrasada {
  background-color: #dc3545 !important;/* Vermelho */
}


.grafico_sombra {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

}

.titulo-chart-disquedenuncia{
  text-transform: uppercase;
  font-weight: bold;
}

.chart-container-disquedenuncia {
  padding: 15px;
  width: 500px;
  height: 500px;
  /* Ajuste o tamanho conforme necessário */
}

.chart-container-disquedenuncia-bar {
  padding: 15px;
  width: 900px;
  /* Ajuste o tamanho conforme necessário */
}

.chart-container-disquedenuncia:hover {
  transform: scale(1.05);
  /* Aumenta o gráfico em 5% ao passar o mouse */
}